<template>
  <v-app>
    <v-content>
      <v-container class="my-7" style="color: #505663">
        <h1
          :style="headerFontSize"
          class="text-center mb-8"
          style="font-family: FMDerana; color: #0277BD"
        >kshu iy fldkafoais</h1>

        <h4 class="font-arjun">
          <span :style="titleFontSize" style="font-weight: bold">{{title[0]}}</span>
          <span
            :class="$vuetify.breakpoint.xsOnly ? 'title': ''"
            class="roboto"
          >{{title[1]}}</span>
          <span :style="titleFontSize" style="font-weight: bold">{{title[2]}}</span>
        </h4>

        <p>
          ආශිර්වාද මංගල සේවා ආයතනය ජනතාව වෙත සිය සේවාව සැපයීම සඳහා ashirwada.lk වෙබ් අඩවිය තොරතුරු සැපයීමේ
          මාධ්‍යයක් ලෙස යොදාගනියි. ඔබ ashirwada.lk වෙබ් අඩවියට පිවිසෙන්නේ නම් පහත සඳහන් කොන්දේසි පිළිගැනීමට බැඳී සිටිය යුතුයි.
        </p>

        <!-- Section 1 -->
        <div class="mt-8">
          <div class="d-flex topic">
            <p class="shrink mr-2">01.</p>
            <p class="shrink">මෙම වෙබ් අඩවිය භාවිතයට අවසර ඇති අය</p>
          </div>

          <P icon="mdi-check" text="ඔබ වයස අවුරුදු 18ට වැඩි විය යුතුය."></P>
          <P icon="mdi-check" text="ගිවිසුමකට ඇතුලත් වීමට නීතිමය බාධාවක් නොතිබිය යුතුය."></P>
          <P icon="mdi-check" text="පහතින් සඳහන් වන කොන්දේසි සියල්ල පිළිගැනීමට එකඟවිය යුතුය."></P>
          <P
            icon="mdi-check"
            text="මෙම වෙබ් අඩවියට පිවිසෙන යම් අයෙකු පහත සඳහන් කොන්දේසි එකක් හෝ සියල්ලම පිළිගැනීමට සූදානම් නැති නම් මෙම වෙබ් අඩවියට ඇතුළු වීමෙන්
          වැළකී සිටිය යුතුය."
          ></P>
        </div>

        <!-- Section 2 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">02.</p>
            <p class="shrink">
              ashirwada.lk වෙබ් අඩවියට ඇතුළු වන්නේ නම් පහතින් සඳහන් කොට ඇති සියළුම කොන්දේසි පිළිගන්නා බවට කඩවත පහළ කරගහමුණ නො.
              53/1/C ස්ථානයේ ලියාපදිංචි කොට ඇති ආශිර්වාද මංගල සේවා ආයතනය හා නීත්‍යානුකූල ගිවිසුමකට මෙයින් ඇතුලත්වේ.
            </p>
          </div>

          <P
            icon="mdi-circle-medium"
            text="මෙම සේවාව මඟින් කෙරෙන්නේ විවාහ අපේක්ෂකයින්ට තමන්ගේ රුචිය පරිදි කෙනෙකු තෝරා ගැනීමට අවස්ථාවක් ලබා දීම
          පමණි. ඉන් පසු තව දුරටත් කටයුතු කරගෙන යාම ඔබ හා එකී පාර්ශවයන්ගේ උවමනාව, කැමැත්ත හා එකඟතාවය මත පමණක් සිදුවේ.
          එවැනි සම්බන්ධතාවයක් පවත්වාගෙන යාම සඳහා කිසිම අයුරකින් අනුබල දීමක් හෝ බලපෑමක් ආශිර්වාද මංගල සේවා ආයතනය මගින්
          නොකෙරේ. යම් සම්බන්ධතාවයක් හේතු කොට ගෙන කිසියම් පාර්ශවයකට සිදුවන ගැටළු, පාඩු හෝ මූල්‍යමය අවාසි සම්බන්ධයෙන් 
          ආශිර්වාද මංගල සේවා ආයතනය වගකියනු නොලැබේ."
          ></P>
          <P
            icon="mdi-circle-medium"
            text="තවද මෙම මංගල සේවයේ ලියාපදිංචිවී ඇති විවාහ අපේක්ෂකයින්ගේ විවාහක අවිවාහක බව, පදිංචි ලිපිනය, රැකියාව ඇතුළු 
          සියළු විස්තර වල සත්‍ය අසත්‍ය භාවය සොයා බැලීමට ආශිර්වාද මංගල සේවා ආයතනය කිසිදු අන්දමේ වගකීමකට බැඳී නොමැත. 
          මෙම වෙබ් අඩවිය මඟින් දැනගන්නා යම් කෙනෙකු සමඟ සම්බන්ධතාවකයක් පටන් ගැනීමට පෙර ඔහු හෝ ඇය සපයා ඇති සියළු 
          තොරතුරු වල සත්‍ය අසත්‍ය තාවය සොයාගැනීම තමන්ගේ කාර්‍ය්‍ය භාරය හා වගකීම බව ඔබ පිළිගත යුතුය. මෙම වෙබ් අඩවිය තුළින් 
          හඳුනාගන්නා කිසිවකු සමඟ විවාහයට පෙර කිසිදු මුදල් ගනුදෙනුවකට සම්බන්ධ නොවන බවටද ඔබ එකඟ වියයුතුවේ."
          ></P>
        </div>

        <!-- Section 3 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">03.</p>
            <p class="shrink">වෙබ් අඩවිය භාවිතය</p>
          </div>

          <P
            icon="mdi-check"
            text="වෙබ් අඩවිය තුළ ලියාපදිංචියේදී ඔබ ඇතුළු සියළුම සාමාජිකයන්, තමන්ගේ ජාතික හැඳුනුම්පත් අංකය හා ජාතික හැඳුනුම්පතෙහි දෙපස ඡායාරූප
          ලබාදිය යුතුයි."
          ></P>
          <P
            icon="mdi-check"
            text="වෙබ් අඩවියට ඇතුළුවන සෑම අවස්ථාවකම ඔබගේ සාමාජික අංකය හා මුරපදය යෙදිය යුතුවේ."
          ></P>
          <P
            icon="mdi-check"
            text="ඔබ ලියාපදිංචියේදී ලබාදුන් තොරතුරු අතුරින් ප‍්‍රායෝගිකව නිතර වෙනස් නොවන තොරතුරු (උදා - ජාතිය, ආගම, කුළය, 
          විවාහක තත්වය, අධ්‍යාපන මට්ටම) වෙනස්කල නොහැකි ලෙස ඔබගේ profile එක save වේ. නොවෙනස්විය යුතු තොරතුරු 
          නිතර වෙනස්වීම සාමාජිකයින් තුළ විශ්වසනීත්වය පලුදු කරයි."
          ></P>
          <P
            icon="mdi-check"
            text="තමන්ගේ ගිණුම ආරක්ෂාකාරීව පවත්වාගෙන යාම තමන්ගේ වගකීම බව සාමාජිකයා පිළිගත යුතුවේ. මුරපදය සුරැකිව තබාගත යුතු
          අතර තමන්ගේ ගිණුමේ යම් වෙනසක් සිදුවුවහොත් එහි වගකීම සාමාජිකයා භාරගත යුතුවේ."
          ></P>
        </div>

        <!-- Section 4 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">04.</p>
            <p class="shrink">අප වෙබ් අඩවියෙහි ලියාපදිංචි වන සාමාජිකයෙකුට ලැබෙන ප‍්‍රතිලාභ</p>
          </div>

          <P
            icon="mdi-check"
            text="අප වෙබ් අඩවියෙහි ලියාපදිංචිවී සිටින සාමාජිකයින් හට, එක් එක් සාමාජිකයා රැඳී සිටින පැකේජයට අදාලව ලැබෙන පහසුකම්,
            කාල සීමාව හා එම පැකේජයන්හි මිල ගණන් පැකේජ පිටුවෙහි පැහැදිලිව දක්වා ඇත."
          ></P>
          <P
            icon="mdi-check"
            text="ඔබ ලබාගත් පැකේජයට අදාල දුරකථන අංක ප‍්‍රමාණය, ඔබගේ යෝජනාවට ගැළපීම් සහිත ඒවා ඇත්නම් එකවර හෝ එසේත් 
            නැත්නම් පැකේජයට අදාල කාලසීමාව තුල දී ඇති උපරිමය දක්වා ලබාගැනීමේ පහසුකම ඇත."
          ></P>
          <P
            icon="mdi-check"
            text="ඔබ ලබාගන්නා සමහර දුරකථන අංක ඇතැම් විටෙක කි‍්‍රයාවිරහිතවී හෝ ප‍්‍රතිචාරනොදී හෝ තිබිය හැක. සමහර සාමාජිකයින් 
            දැනට යෝජනා කතා කරගෙන යනඅය වියහැක. එවැනි අවස්ථාවන් ගැන වෙන වෙනම සොයා බලා හේතු කියාසිටීමට ආයතනය
            වගකීමෙන් බැදී නොමැත."
          ></P>
          <P
            icon="mdi-check"
            text="පැකේජයකට අදාල කාල සීමාව අවසන් වීමට පෙර, එම පැකේජයට අදාල දුරකථන අංක ප‍්‍රමාණය ලබාගෙන අවසන් වුවහොත් 
            තමාගේ කැමැත්ත පරිදි නැවතත් පැකේජයක් අලූතින් ලබාගත හැක."
          ></P>
          <P
            icon="mdi-check"
            text="ආයතනයේ ප‍්‍රතිපත්ති අනුව සාමාජිකයකුගේ දුරකථන අංකය ලබාගැනීමට අවසර ඇත්තේ තවත් සාමාජිකයෙකුට පමණි. 
            පිටස්තර අයට ඒවා ලබාගැනීමේ අවකාශයක් නොමැත. සාමාජිකයන්ගේ දුරකථන අංක ප‍්‍රදර්ශනයවීම බාහිර පුද්ගලයන්ගෙන් සාමාජිකයින්ට 
            හිරිහැර වන අවස්ථා ඇති බැවින් ආයතනය එම පියවර ගෙන ඇත. එබැවින් වෙබ් අඩවියේ දී ඇති සෑම යෝජනාවකම දුරකථන අංක 
            ප‍්‍රදර්ශනය කර නොමැත."
          ></P>
        </div>

        <!-- Section 5 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">05.</p>
            <p class="shrink">පිටපත් කිරීම</p>
          </div>

          <P
            icon="mdi-check"
            text="මෙම වෙබ් අඩවියේ ඇති සියළුම දත්ත හා ඡායාරූප වල අයිතිය ආශිර්වාද මංගල සේවා ආයතනය සතුවේ."
          ></P>
          <P
            icon="mdi-check"
            text="මෙහි එන දත්ත හා ඡායාරූප කිසිවක්, කුමන අන්දමකට හෝ පිටපත් කිරීමට කිසිවෙකුටත් අවසර නොමැත. එය සම්පූර්ණයෙන්ම 
            නීති විරෝධී කි‍්‍රයාවකි."
          ></P>
          <P
            icon="mdi-check"
            text="මෙහි ඵලවී ඇති යම් ඡායාරූපයක් පිටපත් කර ලබාගැනීමෙන් එම සාමාජිකයාට යම් ගැටළුවකට මුහුණදීමට සිදුවුවහොත්, එම 
            වගකීමෙන් ආශිර්වාද මංගල සේවා ආයතනය නිදහස් වන අතර එම කි‍්‍රයාව සිදුකළ පුද්ගලයා ඒ සම්බන්ධ සම්පූර්ණ වගකීම භාරගැනීමට
            බැඳී ඇත. එවැනි තැනැත්තෙකුට විරුද්ධව නීත්‍යාණුකූල පියවර ගැනීමට සිදුවුවහොත් ආශිර්වාද මංගල සේවා ආයතනයටද ඒ සඳහා 
            සම්පූර්ණ බලය ඇත."
          ></P>
        </div>

        <!-- Section 6 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">06.</p>
            <p class="shrink">වෙබ් අඩවිය තුල ඔබගේ වෙළෙඳ දැන්වීමක් ඵළකර ගැනීම</p>
          </div>

          <P
            icon="mdi-check"
            text="අප වෙබ් අඩවිය තුළ වෙළෙඳ දැන්වීමක් ඵලකරගන්නා ආකාරය, දැන්වීම ප‍්‍රදර්ශනය කරනු ලබන කාල සීමාව හා ඒ සඳහා වන 
            මිල ගණන් ආදී සියළු තොරතුරු 0717 928 627 හෝ 0717 928 628 අංකයන්ට ඇමතුමක් ලබාදීමෙන් දැනගත හැක."
          ></P>
        </div>

        <!-- Section 7 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">07.</p>
            <p class="shrink">තොරතුරු වල රහස්‍ය භාවය</p>
          </div>

          <P
            icon="mdi-check"
            text="සාමාජිකයෙකු විසින් ආශිර්වාද මංගල සේවා ආයතනයට සපයා ඇති පිටතට ලබාදිය නොයුතු කිසිම තොරතුරක් පිටතට ලබාදීමට 
            ආයතනය බැඳී නොමැත. එහෙත් නීතියේ අවශ්‍යතාවයක් මත එසේ කිරීමට සිදුවුවහොත් එයට අනුකූලවීමට අප බැඳී සිටී."
          ></P>
        </div>

        <!-- Section 8 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">08.</p>
            <p class="shrink">සාමාජිකත්වය අහෝසි කිරීම</p>
          </div>

          <P
            icon="mdi-circle-medium"
            text="ඕනෑම සාමාජිකයෙකුට ඕනෑම අවස්ථාවකදී තමන්ගේ සාමාජිකත්වය අහෝසි කරගත හැක. ඒ සඳහා ආයතනයට ඇමතුමක්
            ලබාදී තමන්ගේ අනන්‍යතාවය ඔප්පුකළ යුතුවේ."
          ></P>
          <P
            icon="mdi-circle-medium"
            text="එලෙසම පහත සඳහන් කුමන කි‍්‍රයාවක් නිසා හෝ ඔබගේ සාමාජිකත්වය ඉවත් කිරීමට ආශිර්වාද මංගල සේවා ආයතනයට බලය ඇත."
          ></P>
          <P icon="mdi-check" text="තමන්ගේ නොවන දුරකථන අංක ඇතුලත් කිරීම."></P>
          <P icon="mdi-check" text="තමන්ගේ නොවන ඡායාරූප ඵළ කිරීම."></P>
          <P icon="mdi-check" text="වෙබ් අඩවියේ ඇති දත්ත හා ඡායාරූප පිටපත් කිරීම හෝ සොරකම් කිරීම."></P>
          <P icon="mdi-check" text="දුරකථන ඇමතුම් වලට යහපත් ප‍්‍රතිචාර නොදීම."></P>
          <P
            icon="mdi-check"
            text="තවමත් කටයුත්තක් තීන්දුනොවී තිබියදී හා තමන්ගේ profile එක වෙබ් අඩවියේ තබාගෙන සිටියදී වෙනත් සාමාජිකයෙකු 
            කථා කළවිට ''අපට යෝජනාවක් හරි ගිහිල්ලා යයි කීම''."
          ></P>
          <P
            icon="mdi-check"
            text="ආයතනය විසින් සාමාජිකයාගේ ලිපිනයට තැපැල් මඟින් යවනු ලබන ලිපි ආපසු ඒම."
          ></P>
          <P
            icon="mdi-check"
            text="මෙම සේවය තුලින් හඳුනාගන්නා අය සමඟ මුදල් ගනුදෙනු වලට සම්බන්ධවීම."
          ></P>
          <P icon="mdi-check" text="මෙම ගිවිසුමේ සඳහන් කොන්දේසි එකක් හෝ එකට වැඩි ගණනක් කඩ කිරීම."></P>
          <P
            icon="mdi-circle-medium"
            text="එමෙන්ම යම් සාමාජිකයෙකු විසින් තවත් සාමාජිකයෙකුට විරුද්ධව අප වෙත ඉදිරිපත් කරනු ලබන පැමිීණිල්ලක් සම්බන්ධව 
            ආයතනික මටිටමේ විමර්ශනයකින් පසුව පූර්ව දැනුම් දීමකින් තොරව එවැනි සාමාජිකයන්ගේ සාමාජිකත්වය අහෝසි කිරීමට ආශිර්වාද 
            මංගල සේවා ආයතනයට සම්පූර්ණ බලය ඇත."
          ></P>
        </div>

        <!-- Section 9 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">09.</p>
            <p class="shrink">තමන්ගේ නොවන විස්තර ඇතුලත් කිරීම</p>
          </div>

          <P
            icon="mdi-circle-medium"
            text="යම් සාමාජිකයෙකු විසින් තමන්ගේ නොවන ඡායාරූපයක් හෝ දුරකථන අංකයක් තමන්ගේ profile එකට ඇතුලත් කර ඇත්නම්, 
            ඒ බව දැනගන්නා යම් තැනැත්තෙකුට එම කරුණ සම්බන්ධව ආශිර්වාද මංගල සේවා ආයතනයට දුරකථනයෙන් දැනුම්දීමට හැකි අතර 
            ආශිර්වාද මංගල සේවා ආයතනය වහාම කි‍්‍රයාත්මකවී එවැනි තොරතුරු වෙබ් අඩවියෙන් ඉවත් කිරීමට අවශ්‍ය පියවර ගනු ඇත."
          ></P>
          <P
            icon="mdi-circle-medium"
            text="මෙවැන්නක් කිරීමෙන් ඡායාරූපයේ හෝ දුරකථනයේ සැබෑ අයිතිකරුට විදින්නට වෙන ඕනෑම අන්දමේ ගැටළුවක් හෝ පාඩුවක් 
            සම්බන්ධ සම්පූර්ණ වගකීම එවැනි සාවද්‍ය තොරතුරු තමන්ගේ profile එකට ඇතුලත් කරගෙන සිටි සාමාජිකයා විසින් භාරගතයුතු 
            අතර ආශිර්වාද මංගල සේවා ආයතනය ඒ සියල්ලෙන්ම නිදහස්වේ. එවැනි තැනැත්තෙකුට විරුද්ධව නීතිමය පියවර ගැනීමට ආශිර්වාද 
            මංගල සේවා ආයතනයට ද සම්පූර්ණ බලය ඇත."
          ></P>
        </div>

        <!-- Section 10 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">10.</p>
            <p class="shrink">මුදල් ආපසු දීමේ ප‍්‍රතිපත්තිය</p>
          </div>

          <P
            icon="mdi-circle-medium"
            text="කිසිදු හේතුවක් මත ආයතනය වෙත ගෙවනු ලබන මුදල් ආපසු ගෙවීමක් සිදුකරනු නොලැබේ."
          ></P>
        </div>

        <!-- Section 11 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">11.</p>
            <p class="shrink">ගිවිසුමේ සඳහන් කොන්දේසි</p>
          </div>

          <P
            icon="mdi-circle-medium"
            text="මෙම ගිවිසුමේ සඳහන් කොන්දේසි කිසිම පුර්ව දැනුම්දීමකින් තොරව වෙනස් කිරීමේ හෝ අළුතින් කොන්දේසි එකතු කිරීමේ 
            සම්පූර්ණ බලය ආශිර්වාද මංගල සේවා ආයතනය සතුය. එබැවින් කළින් කලට මෙම කොන්දේසි පරික්ෂා කිරීම ඔබගේ වගකීම වේ."
          ></P>
        </div>

        <!-- Section 12 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">12.</p>
            <p class="shrink">බලපවත්වන නීතිය</p>
          </div>

          <P
            icon="mdi-circle-medium"
            text="ඕනෑම සාමාජිකයෙකු ආශිර්වාද මංගල සේවා ආයතනය වෙත ලියාපදිංචි වූ විට ඔහු හෝ ඇය ආශිර්වාද මංගල සේවා ආයතනය 
            සමඟ මෙම ලියවිල්ලේ සඳහන් කරුණු කාරණා වලට යටත්ව ගිවිසුමකට එළඹෙන බවට සැලකෙන අතර එය කඩවතදී නීතිගත වූ බවට
            සැලකේ."
          ></P>
        </div>

        <!-- Section 13 -->
        <div class="mt-5">
          <div class="d-flex topic">
            <p class="shrink mr-2">13.</p>
            <p class="shrink">ආරවුල් බේරුම් කිරීම</p>
          </div>

          <P
            icon="mdi-circle-medium"
            text="ashirwada.lk වෙබ් අඩවිය හා ආශිර්වාද මංගල සේවා ආයතනය සම්බන්ධයෙන් වන සියළුම ආරවුල් වර්ෂ 1995 අංක 11 
            ආරවුල් බේරුම් කිරීමේ පනත යටතේ බේරුම්කරුවන් තුන්දෙනෙකු ගෙන් සැදුම්ලත් බේරුම්කරන සභාවක් ඉදිරියේදී කොළඹදී විභාගයට 
            ගැනීමට ඔබ හා ආශිර්වාද මංගල සේවා ආයතනය මෙයින් එකඟවේ."
          ></P>
        </div>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import P from "../components/P";

export default {
  components: {
    P
  },

  data: () => ({
    title: ['wdYs¾jdo ux., fiajfha fjí wvúh jk ', 'www.ashirwada.lk ', 'fj; Tn idorfhka ms<s.ksuq']
  }),

  computed: {
    headerFontSize: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size: 2.5em";
        case "sm":
          return "font-size: 2.75em";
        case "md":
          return "font-size: 3em";
        default:
          return "font-size: 3.4em";
      }
    },
    titleFontSize: function () {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "font-size: 1.1em";
        case "sm":
          return "font-size: 1.2em";
        default:
          return "font-size: 1.25em";
      }
    },
  }
}
</script>

<style scoped>
.topic {
  font-weight: bold;
}
</style>